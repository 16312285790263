// Import Font Awesome CSS
import '@fortawesome/fontawesome-svg-core/styles.css';

// Create a custom Font Awesome library
import { config, library } from '@fortawesome/fontawesome-svg-core';

import {
  faAngleDown,
  faBars,
  faPlus,
  faRedoAlt,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';

import {
  faWizardsOfTheCoast,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faAngleDown,
  faBars,
  faPlus,
  faRedoAlt,
  faUndo,
  faWizardsOfTheCoast,
);

// Prevent inline <style> tag
// https://fontawesome.com/how-to-use/on-the-web/other-topics/security#policy
config.autoAddCss = false;
