import styles from './Card.module.scss';

function Card({ image, name }) {
  return (
    <div className={styles.Card}>
      <div className={styles.ImageWrapper}>
        <div className={styles.Image}>
          {image && (
            <img src={image} alt={name} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Card;
