import React, { useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { CardsContext } from './contexts';
import Header from './Header';
import Card from './Card';
import cardStyles from './Card.module.scss';
import styles from './App.module.scss';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const { useCardsDispatch, useCardsState } = CardsContext;

function App() {
  const [rowHeight /* , setRowHeight */] = useState(280);
  const dispatch = useCardsDispatch();
  const { cards } = useCardsState();

  // Render grid
  const cols = 6;
  const children = React.useMemo(() => (
    cards.map((card, index) => (
      <div
        key={index}
        data-grid={{ x: index % cols, y: Math.floor(index / cols), w: 1, h: 1 }}
      >
        <Card
          image={card.image_uris ? card.image_uris.normal : undefined}
          name={card.name}
          onChange={(data) => {
            /*
            const newCards = [
              ...cards.slice(0, index),
              data,
              ...cards.slice(index + 1),
            ];
            localStorage.setItem('cards', JSON.stringify(newCards.map(({ id }) => id)));
            setCards(newCards);
            */
          }}
        />
      </div>
    ))
  ), [cards]);
  return (
    <div className={styles.App}>
      <Header
        onAdd={() => {
          dispatch({
            type: 'add',
            card: {},
          });
        }}
      />
      <ResponsiveReactGridLayout
        className={`${styles.Main} ${styles['Columns-' + cols]}`}
        draggableHandle={`.${cardStyles.ImageWrapper}`}
        cols={{ xxs: cols, xs: cols, sm: cols, md: cols, lg: cols }}
        rowHeight={rowHeight}
        /*
        onWidthChange={({ containerWidth }) => {
          console.log(cols);
          setRowHeight(containerWidth / cols * 3);
        }}
        */
      >
        {children}
      </ResponsiveReactGridLayout >
    </div>
  );
}

export default App;
