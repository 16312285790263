import R from './R.svg';
import G from './G.svg';
import B from './B.svg';
import W from './W.svg';
import U from './U.svg';
import UB from './UB.svg';
import BR from './BR.svg';
import WB from './WB.svg';
import BG from './BG.svg';
import X from './X.svg';
import Number0 from './0.svg';
import Number1 from './1.svg';
import Number2 from './2.svg';
import Number3 from './3.svg';
import Number4 from './4.svg';
import Number5 from './5.svg';
import Number6 from './6.svg';
import Number7 from './7.svg';
import Number8 from './8.svg';
import Number9 from './9.svg';
import Number10 from './10.svg';

const mana = {
  R,
  G,
  B,
  W,
  U,
  'B/R': BR,
  'U/B': UB,
  'W/B': WB,
  'B/G': BG,
  X,
  0: Number0,
  1: Number1,
  2: Number2,
  3: Number3,
  4: Number4,
  5: Number5,
  6: Number6,
  7: Number7,
  8: Number8,
  9: Number9,
  10: Number10,
};

export default mana;
