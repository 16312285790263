import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

const CardsStateContext = createContext();
const CardsDispatchContext = createContext();

const reducer = (state, action) => {
  let returnValue = {
    ...state,
    history: [...state.history],
    cards: [...state.cards],
  };
  const pushHistory = () => {
    returnValue.history = returnValue.history.slice(0, returnValue.historyIndex + 1);
    returnValue.history.push(returnValue.cards);
    returnValue.historyIndex = returnValue.history.length - 1;
  };
  switch (action.type) {
    case 'set': {
      returnValue.cards = action.cards;
      pushHistory();
      break;
    }
    case 'add': {
      returnValue.cards = [...state.cards, action.card];
      pushHistory();
      break;
    }
    case 'clear': {
      returnValue.cards = [];
      pushHistory();
      break;
    }
    case 'remove': {
      returnValue.cards = [...state.cards].filter(({ id }) => id !== action.id);
      pushHistory();
      break;
    }
    case 'undo': {
      if (returnValue.historyIndex > 0) {
        returnValue.historyIndex -= 1;
        returnValue.cards = returnValue.history[returnValue.historyIndex];
      }
      break;
    }
    case 'redo': {
      if (returnValue.historyIndex < returnValue.history.length - 1) {
        returnValue.historyIndex += 1;
        returnValue.cards = returnValue.history[returnValue.historyIndex];
      }
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
  localStorage.setItem('cards', JSON.stringify(returnValue.cards.map(({ id }) => id)));
  return returnValue;
};

const CardsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    cards: [],
    history: [],
    historyIndex: 0,
  });

  // Load stored card IDs from localStorage once on page load
  useEffect(() => {
    (async () => {
      const ids = JSON.parse(localStorage.getItem('cards') || '[]');

      // Load card images
      const cards = !ids[0] ? [] :
        await fetch('https://api.scryfall.com/cards/collection', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            identifiers: ids.map((id) => ({ id })),
          }),
        })
        .then((res) => res.json())
        .then((data) => data.data);

      dispatch({ type: 'set', cards });
    })();
  }, [dispatch]);

  return (
    <CardsStateContext.Provider value={{
      ...state,
      hasRedo: state.historyIndex < state.history.length - 1,
      hasUndo: state.historyIndex > 0,
    }}>
      <CardsDispatchContext.Provider value={dispatch}>
        {children}
      </CardsDispatchContext.Provider>
    </CardsStateContext.Provider>
  );
};
CardsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useCardsState = () => {
  const context = useContext(CardsStateContext);
  if (context === undefined) {
    throw new Error('useCardsState must be used within a CardsProvider');
  }
  return context;
};

const useCardsDispatch = () => {
  const context = useContext(CardsDispatchContext);
  if (context === undefined) {
    throw new Error('useCardsDispatch must be used within a CardsProvider');
  }
  return context;
};

const CardsContext = {
  CardsProvider,
  useCardsState,
  useCardsDispatch,
};

export default CardsContext;
