import React, { useEffect, useState } from 'react';
import { CardsContext } from './contexts';
import Select, { Option } from 'rc-select';
import mana from './mana';
import styles from './Card.module.scss';

const { useCardsDispatch } = CardsContext;

function Search() {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState();
  const dispatch = useCardsDispatch();
  const cards = (response && response.data || []).filter(({ object }) => object === 'card');
  useEffect(() => {
    // Debounce API requests
    const timeout = setTimeout(() => {
      if (query) {
        const url = new URL('https://api.scryfall.com/cards/search');
        const params = {
          q: query,
        };
        url.search = new URLSearchParams(params).toString();
        fetch(url)
          .then((res) => res.json())
          .then((data) => setResponse(data));
      }
    }, 200);
    return () => clearTimeout(timeout);
  }, [query]);
  return (
    <Select
      showSearch
      placeholder="search…"
      filterOption={false}
      dropdownClassName="SelectDropdown"
      onSearch={setQuery}
      onSelect={(selectedId) => {
        const item = cards.find(({ id }) => id === selectedId);
        dispatch({ type: 'add', card: item });
      }}
    >
      {cards.map((card) => (
        <Option className={styles.CardOption} key={card.id} value={card.id}>
          <span className={styles.Name}>{card.name}</span>
          <span>
            {((card.mana_cost || '').match(/\{[^}]+\}/g) || []).map((cost, index) => {
              const icon = cost.slice(1, -1);
              return <span key={index}><img src={mana[icon]} alt={icon} /></span>;
            })}
          </span>
        </Option>
      ))}
    </Select>
  );
}

export default Search;
