import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardsContext } from '../contexts';
import Search from '../Search';
import styles from './Header.module.scss';

const { useCardsDispatch, useCardsState } = CardsContext;

const Header = ({ onAdd }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    hasRedo,
    hasUndo,
  } = useCardsState();
  const dispatch = useCardsDispatch();
  return (
    <div className={styles.Header}>
      <div>
        <span className={styles.Logo}>
          <FontAwesomeIcon icon={['fab', 'wizards-of-the-coast']} />
        </span>
      </div>
      <div className={styles.Search}>
        <Search />
      </div>
      <div className={styles.Buttons}>
        {/*
        <button
          type="button"
          onClick={onAdd}
          aria-label="Add card"
        >
          <FontAwesomeIcon icon={['fas', 'plus']} />
        </button>
        */}
        <button
          type="button"
          disabled={!hasUndo}
          onClick={() => dispatch({ type: 'undo' })}
          aria-label="Undo"
        >
          <FontAwesomeIcon icon={['fas', 'undo']} />
        </button>
        <button
          type="button"
          disabled={!hasRedo}
          onClick={() => dispatch({ type: 'redo' })}
          aria-label="Redo"
        >
          <FontAwesomeIcon icon={['fas', 'redo-alt']} />
        </button>
        <button
          type="button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Toggle main menu"
        >
          <FontAwesomeIcon icon={['fas', 'bars']} />
        </button>
      </div>
      <nav className={`${styles.Menu} ${isMenuOpen ? styles.Open : ''}`}>
        <h2>Main menu</h2>
        <ul>
          <li>
            <a href="/">My account</a>
          </li>
          <li>
            <button
              type="button"
              onClick={() => {
                dispatch({ type: 'clear' });
                setIsMenuOpen(false);
              }}
            >
              Clear list
            </button>
          </li>
          <li>
            <a href="/">Logout</a>
          </li>
        </ul>
      </nav>
      <button
        type="button"
        onClick={() => setIsMenuOpen(false)}
        aria-label="Close main menu"
      />
    </div>
  );
};

export default Header;
